(function(document) {

	document.addEventListener("DOMContentLoaded", function() {
		var headContainer = document.head;
		var bodyContainer = document.body;
		var userId = window.edlio.currentUserId ? window.edlio.currentUserId : 0;

		var dataLayerRequest = new XMLHttpRequest();

		dataLayerRequest.open('GET', '/apps/analytics/datalayer?userId=' + userId, true);
		dataLayerRequest.send();
		dataLayerRequest.onload = function(){
			if (dataLayerRequest.status === 200) {
				var response = JSON.parse(dataLayerRequest.responseText);
				if (response.dataLayer != "") {
					var dtaLayerObject = JSON.parse(response.dataLayer);
					if (window.dataLayer === undefined) {
						window.dataLayer = [];
					}
					window.dataLayer.push(dtaLayerObject);
					initGoogleTagManager();
					createBodyTag();
				}
			}
		};

		function initGoogleTagManager () {
			var request = new XMLHttpRequest();
			request.open('GET', '/apps/analytics/header', true);
			request.send();
			request.onload = function(){
				if (request.status === 200) {
					var response = JSON.parse(request.responseText);

					function activateGtm(w,d,s,l, analytics) {
						w[l]=w[l]||[];
						w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
						var f=d.getElementsByTagName(s)[0];
						var j=d.createElement(s);
						var dl=l!='dataLayer'?'&l='+l:'';
						j.async=true;
						j.src='https://www.googletagmanager.com/gtm.js?id=' + analytics.googleID + dl +
						'&gtm_auth=' + analytics.googleAuth +
						'&gtm_preview=' + analytics.googlePreview + '&gtm_cookies_win=x';
						f.parentNode.insertBefore(j,f);
					}

				    	activateGtm(window, document, 'script', 'dataLayer', response);
				}
			}
		}

		function createBodyTag () {
			var request = new XMLHttpRequest();
			request.open('GET', '/apps/analytics/body?userId=' + userId, true);
			request.send();
			request.onload = function(){
				if (request.status === 200) {
					var response = JSON.parse(request.responseText);
					var bodyTag = createElementFromHTML(response.tag);
					if (!bodyContainer.contains(bodyTag)) {
						bodyContainer.insertBefore(bodyTag, bodyContainer.firstChild);
					}
				}
			}
		}

		function createElementFromHTML(htmlString) {
			var div = document.createElement('div');
			div.innerHTML = htmlString.trim();
			return div.firstChild;
		}

	});

})(document);
